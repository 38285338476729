<template>
  <div class="page-box">
    <base-screen>
      <a-form-model ref="formRef" layout="inline" :model="params" @submit.native.prevent>
        <a-form-model-item label="活动名称" prop="activityName">
          <a-input v-model="params.activityName" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="活动状态" prop="activityState">
          <a-select v-model="params.activityState" placeholder="请选择" style="min-width: 120px">
            <a-select-option
              v-for="item in groupActivityStatus"
              :key="item.value"
              :value="item.value"
            >{{item.name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="handleSearch">查询</a-button>
          <a-button @click="handleReset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </base-screen>
    <div class="btn-box">
      <a-button type="primary" @click="$router.push('/activity/group/add')" v-pms="'add'">新建拼团活动</a-button>
    </div>
    <base-table
      bordered
      :columns="columns"
      :dataSource="tableData"
      :pagination="pagination"
      :rowKey="record => record.groupActivityId"
      :loading="tableLoading"
      @change="handleTableChange"
    >
      <template slot="activityName" slot-scope="text,record">
        <a-popover title placement="topLeft">
          <template slot="content">
            <p>{{text}}</p>
          </template>
          <a-button
            type="link"
            :title="text"
            class="el"
            style="padding: 0;max-width:200px"
            @click="$router.push(
        `/activity/group/detail/${record.groupActivityId}`
      )"
          >{{text}}</a-button>
        </a-popover>
      </template>
      <template slot="productName" slot-scope="text,record">
        <a-popover title placement="topLeft">
          <template slot="content">
            <p>{{text}}</p>
          </template>
          <a-button
            type="link"
            :title="text"
            class="el"
            style="padding: 0;max-width:200px"
            @click="$router.push(
        `/goods/goods_detail/${record.productId}`
      )"
          >{{text}}</a-button>
        </a-popover>
      </template>
      <template
        slot="beginTime"
        slot-scope="text, record"
      >{{moment(record.beginTime).format("YYYY-MM-DD HH:mm:ss")}}</template>
      <template
        slot="endTime"
        slot-scope="text, record"
      >{{moment(record.endTime).format("YYYY-MM-DD HH:mm:ss")}}</template>
      <div class="table-operations" slot="operation" slot-scope="text, record">
        <a-button
          type="link"
          :disabled="record.activityState === 2"
          v-pms="'edit'"
          @click="$router.push('/activity/group/edit/'+record.groupActivityId)"
        >编辑</a-button>
        <a-popconfirm
          placement="topRight"
          title="确定要删除？"
          ok-text="确定"
          v-if="record.activityState === 2"
          cancel-text="取消"
          @confirm="()=>deleteItem(record.groupActivityId)"
        >
          <a-button type="link" v-pms="'delete'">删除</a-button>
        </a-popconfirm>
        <a-popconfirm
          placement="topRight"
          title="确定要关闭？"
          ok-text="确定"
          v-if="record.activityState !== 2"
          cancel-text="取消"
          @confirm="()=>confirmDelete(record.groupActivityId)"
        >
          <a-button type="link" v-pms="'disabled'">关闭</a-button>
        </a-popconfirm>
      </div>
    </base-table>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, onUnmounted } from '@vue/composition-api'
import { activity } from '@/api'
import { SHOPID } from '@/constants'
import { getSession } from '@/utils/session'
import { groupActivityStatus } from '@/utils/constants'
import moment from 'moment'
const columns = [
  {
    title: '活动名称',
    dataIndex: 'activityName',
    scopedSlots: { customRender: 'activityName' }
  },
  {
    title: '商品名称',
    dataIndex: 'productName',
    scopedSlots: { customRender: 'productName' }
  },
  {
    title: '活动状态',
    dataIndex: 'activityState',
    customRender: x => {
      return groupActivityStatus.find(item => item.value === x).name
    }
  },
  {
    title: '开始时间',
    dataIndex: 'beginTime',
    scopedSlots: { customRender: 'beginTime' }
  },
  {
    title: '结束时间',
    dataIndex: 'endTime',
    scopedSlots: { customRender: 'endTime' }
  },
  {
    title: '成团人数',
    dataIndex: 'userNum'
  },
  {
    title: '成团订单数',
    dataIndex: 'orderNum'
  },
  {
    title: '销售金额',
    dataIndex: 'salesAmount'
  },
  {
    title: '操作',
    width: 200,
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' }
  }
]
export default {
  name: 'PageGroup',
  setup (props, { root }) {
    const state = reactive({
      params: {
        shopId: getSession(SHOPID)
      },
      formRef: null,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0
      },
      tableData: [],
      tableLoading: false
    })
    const callback = () => {
      getTableData()
    }
    onMounted(() => {
      getTableData()
      root.$bus.$on('PageGroupList:refresh', callback)
    })
    onUnmounted(() => {
      root.$bus.$off('PageGroupList:refresh', callback)
    })
    async function getTableData () {
      state.tableLoading = true
      let { code, data, page, msg } = await activity.getGroupActivityPage({
        current: state.pagination.current,
        size: state.pagination.pageSize,
        ...state.params
      })
      state.tableLoading = false
      if (code === '00000') {
        state.tableData = data
        state.pagination.current = page.pageNo
        state.pagination.pageSize = page.pageSize
        state.pagination.total = page.total
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }
    function handleTableChange ({ current }) {
      state.pagination.current = current
      getTableData()
    }

    function handleSearch () {
      state.pagination.current = 1
      getTableData()
    }

    function handleReset () {
      state.formRef.resetFields()
      handleSearch()
    }

    async function confirmDelete (shopRoleId) {
      let { code, msg } = await activity.invalidGroupActivity({ groupActivityId: shopRoleId })
      if (code === '00000') {
        root.$message.success('操作成功')
        handleSearch()
      } else {
        root.$message.error(msg || '操作失败')
      }
    }
    async function deleteItem (id) {
      const { code, msg } = await activity.deleteGroupACtivity({
        groupActivityId: id
      })
      if (code === '00000') {
        root.$message.success('删除成功')
        handleSearch()
      } else {
        root.$message.error(msg || '删除失败')
      }
    }

    return {
      moment,
      groupActivityStatus,
      columns,
      ...toRefs(state),
      confirmDelete,
      handleReset,
      handleSearch,
      handleTableChange,
      getTableData,
      deleteItem
    }
  }
}
</script>

<style lang="less" scoped>
.dialog-foot {
  padding-top: 18px;
  margin-top: 18px;
  border-top: 1px solid #ddd;
}
::v-deep .ant-form-item {
  margin-bottom: 8px;
}
.role-name-box {
  display: flex;
  align-items: center;
  .official-flag {
    background: @main-color;
    color: #fff;
    padding: 4px;
    font-size: 12px;
    line-height: 12px;
    border-radius: 2px;
    margin-left: 6px;
    font-weight: bolder;
    flex-shrink: 0;
  }
}
.tips {
  font-size: 12px;
  color: #666;
  line-height: 24px;
}
.btn-box {
  margin-bottom: 18px;
  ::v-deep .ant-btn {
    margin-right: 12px;
    &:last-child {
      margin: 0;
    }
  }
}
</style>
